


















import Vue from "vue";
import { Event } from "@/models/Types/Event/Event";
import TimelineContent from "@/components/Pages/Timeline/TimelineContent.vue";

export default Vue.extend({
  name: "EventsTimeline",
  components: {
    TimelineContent
  },
  props: {
    events: { type: Array as () => Event[] }
  }
});
