




















import Vue from "vue";
import EventsTimeline from "@/components/Pages/Timeline/EventsTimeline.vue";
import { Event } from "@/models/Types/Event/Event";
import { dataEvents } from "@/models/Types/Event/DataEvents";

export default Vue.extend({
  components: {
    EventsTimeline
  },
  async mounted() {
    this.events = await dataEvents;
  },
  data() {
    const events: Event[] = [];
    return {
      events
    };
  }
});
