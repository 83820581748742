

































import { Event } from "@/models/Types/Event/Event";
import Vue from "vue";
export default Vue.extend({
  name: "TimelineContent",
  props: {
    index: Number,
    event: Object as () => Event
  }
});
